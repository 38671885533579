<template>
  <div v-if="getLoaded()" class="duck-form form-width-medium form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">
      

      <fieldset class="form-block">
        <div class="h-title">
          <h3>ข้อมูลรายละเอียด</h3>
        </div>
        <div class="form-container">
          <div class="input">
            <Label text="นิติบุคคล" />
            <select v-model="contentData.meta.business_type">
              <option value="1">บุคคลธรรมดา</option>
              <option value="9">นิติบุคคล</option>
            </select>
          </div>
          
          <div class="input">
            <Label text="ชื่อองค์กร" />
            <input type="text" v-model="contentData.meta.company_name">
          </div>
          
          <div class="input">
            <Label text="ระดับผู้รับเหมา" />
            <select v-model="contentData.meta.level">
              <option value="100">Bronze</option>
              <option value="200">Silver</option>
              <option value="300">Gold</option>
            </select>
          </div>
          
          <div class="input">
            <Label text="รายละเอียดงานที่รับ" />
            <input type="text" v-model="contentData.meta.service_type">
          </div>
          
          <div class="input">
            <Label text="เริ่มดำเนินธุรกิจตั้งแต่" />
            <input type="text" v-model="contentData.meta.start_business_year">
          </div>
          
          <div class="input">
            <Label text="ทุนจดทะเบียนบริษัท" />
            <input type="text" v-model="contentData.meta.capital_registered">
          </div>
          
          <div class="input">
            <Label text="มูลค่างานที่รับ" />
            <input type="text" v-model="contentData.meta.job_value">
          </div>
          
          <div class="input">
            <Label text="มูลค่ายอดขายต่อปี" />
            <input type="text" v-model="contentData.meta.income_per_year">
          </div>
          
          <div class="input">
            <Label text="มีโรงงานผลิตขนาด" />
            <input type="text" v-model="contentData.meta.factory_size">
          </div>
          
          <div class="input">
            <Label text="จำนวนพนักงาน" />
            <input type="text" v-model="contentData.meta.total_employee">
          </div>
        </div>
      </fieldset>

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import getPostItem        from '@/utils/post/getPostItem.js';
import savePostItem       from '@/utils/post/savePostItem.js';
import preparePostItem    from '@/utils/post/preparePostItem.js';


import NotiBar            from '@/components/common/NotiBar.vue';
import Label              from '@/components/common/form/Label.vue';

export default {
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentId: '',
      contentData: {
        publish: {},
      },
      isEditForm: false,
    }
  },
  components: {
    Label,
    NotiBar,
  },
  created() {
    this.contentId = this.$route.params.id;
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);

      getPostItem(this.contentId)
        .then((res) => {
          this.contentData = preparePostItem(res);

          if(this.contentData.meta.business_type==undefined) {
            this.contentData.meta.business_type = 1;
            this.contentData.meta.level = 1;
          }

          this.isEditForm = this.contentData.formType == 'edit';

          this.setPageTitle();
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);
      // console.log(this.contentData)

      savePostItem(this.contentData)
        .then( (res) => {
          this.msg.show = true;
          
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';
            this.isEditForm = true; // force change this page to Edit Form

            this.setPageTitle();
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }

          this.$store.commit('setLoaderShow', false);
        });
    },

    /**
     * Set page title to 'Add' or 'Edit'
     */
    setPageTitle() {
      if(this.pageTitle!=='') {
        let title = ( this.isEditForm ? 'Edit ':'Add ' ) + 'Recommended';
        this.$store.commit('setPageTitle', title);
        this.$store.commit('setHeadTitle', title);
      }
    },
  }
}
</script>